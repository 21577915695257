import { type ReactNode, useRef } from 'react';
import classNames from 'classnames';

import { useClickOutside } from '@jsmdg/yoshi';
import styles from './CustomerFlyout.module.scss';

type CustomerFlyoutProps = {
    readonly onRequestClose: () => void;
    readonly children: ReactNode;
    readonly dataTestId: string;
};

const CustomerFlyout = ({
    children,
    dataTestId,
    onRequestClose,
}: CustomerFlyoutProps): JSX.Element => {
    const node = useRef<HTMLDivElement>(null);
    useClickOutside(node, onRequestClose);

    return (
        <div
            className={classNames('position-absolute end-0 d-grid mt-1x', styles.wrapper)}
            ref={node}
            role="none"
            data-testid={dataTestId}
        >
            {children}
        </div>
    );
};

export { CustomerFlyout };
